import Modeler from 'bpmn-js/lib/Modeler'

import camundaExtensionsModule from 'camunda-bpmn-moddle/lib'
import propertiesPanelModule from 'bpmn-js-properties-panel'
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
import minimapModule from 'diagram-js-minimap'

import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'
import 'diagram-js-minimap/assets/diagram-js-minimap.css'

let modeler = null
function createSchema (container) {
  modeler = new Modeler({
    container,
    keyboard: {
      bindTo: document,
    },
    additionalModules: [
      propertiesPanelModule,
      propertiesProviderModule,
      camundaExtensionsModule,
      minimapModule,
    ],
    readOnly: true,
    minimap: {
      open: true,
    },
    propertiesPanel: {
      parent: '#properties-panel-parent',
    },
    moveTool: false,
  })
}

function getModeler (diagram) {
  modeler
    .importXML(diagram)
    .then(({ warnings }) => {
      if (warnings.length) {
        console.log(warnings)
      }

      const canvas = modeler.get('canvas')

      canvas.zoom('fit-viewport')
    })
    .catch((err) => {
      console.error(err)
    })
  return modeler
}

export { createSchema, getModeler }
