<template>
  <div class="wrapper-instruction">
    <v-switch
      v-model="schemeActive"
      :label="`Показать схемы`"
    />
    <div
      class="wrapper-bpmn"
      ref="wrapperBpmn"
      v-show="schemeActive"
    >
      <div
        id="container"
        ref="container"
        class="container-bpmn"
      />
      <div
        id="properties-panel-parent"
        class="properties-panel-parent"
      />
      <div class="zoom-control">
        <div @click="plus">
          <v-icon large>
            mdi-plus
          </v-icon>
        </div>
        <div @click="minus">
          <v-icon large>
            mdi-minus
          </v-icon>
        </div>
      </div>
    </div>
    <v-container
      v-show="!schemeActive"
      id="dashboard"
      fluid
      tag="section"
    >
      <v-card
        class="mt-0"
        style="height: 96vh"
      >
        <iframe
          :src="url"
          frameborder="0"
          style="height: 100%; width: 100%"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { createSchema, getModeler } from '@/plugins/bpmn.js'
  import diagram from './../../assets/Instructions.bpmn'
  export default {
    name: 'Instructions',

    data () {
      return {
        itemWikiS: '',
        url: '',
        modeler: '',
        canvas: '',
        schemeActive: false,
        schemeLoaded: false,
      }
    },
    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    mounted () {
      this.url = this.$store.state.url.replace('api/', '') + 'otrs/public.pl'
    },

    methods: {
      plus () {
        this.modeler.get('canvas').zoom(this.modeler.get('canvas').zoom() * 1.2)
      },
      minus () {
        this.modeler.get('canvas').zoom(this.modeler.get('canvas').zoom() * 0.8)
      },
      getModeler () {
        this.canvas = this.modeler.get('canvas')
        this.$refs.wrapperBpmn.addEventListener('wheel', (event) => {
          // Определяем направление движения колесика мышки
          if (event.deltaY > 0) {
            this.minus()
          } else this.plus()
        })
        const eventBus = this.modeler.get('eventBus')
        const events = [
          'element.hover',
          'element.out',
          'element.click',
          'element.dblclick',
          'element.mousedown',
          'element.mouseup',
        ]
        events.forEach((event) => {
          eventBus.on(event, 1500, (e) => {
            const selected = this.modeler.get('selection')
            if (e.type === 'element.click' || e.element.type === 'bpmn:Collaboration') {
              this.getLink()
              selected.select(null)
            } else e.stopPropagation()
          })
        })
      },
      getLink () {
        const textElem = document.getElementById('camunda-documentation')
        if (textElem.textContent) {
          window.open(textElem.textContent, '_blank')
        }
      },
      searchwiki () {
        window.location.href = 'http://it/projects/sysadmin/search?utf8=✓&wiki_pages=1&q=' + this.itemWikiS
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
    watch: {
      schemeActive () {
        if (this.schemeActive && !this.schemeLoaded) {
          this.$nextTick(() => {
            this.schemeLoaded = true
            createSchema(this.$refs.container)
            this.modeler = getModeler(diagram)
            this.getModeler()
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.wrapper-instruction {
  height: calc(100% - 64px);
}
.container--fluid {
  div {
    height: calc(100vh - 110px) !important;
  }
}
.zoom-control {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  gap: 10px;
  div {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.wrapper-instruction {
  .v-input {
    margin: 0;
    padding: 0;
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin: 20px;
    }
  }
}
.wrapper-bpmn {
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: none;
}
.container-bpmn {
  height: 100%;
}
.djs-context-pad {
  display: none !important;
}
.djs-palette {
  display: none;
}
.properties-panel-parent {
  position: absolute;
  top: 0;
  left: -1000%;
  opacity: 0;
}
</style>
